<template>
   <!-- <center> -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer__content">
                            <div class="footer__social">
                                <img style="width: 100px;" src="../../assets/image/zain_gray.png" alt="">
                                </div>
                                 <span>
                             <small class="footer__copyright">Made by BYTE Co.ltd © 2022</small> 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    <!-- </center> -->
</template>

<script>
export default {
   name: 'AppFooter'
}
</script>

<style>

</style>